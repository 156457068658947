<style lang="scss" scoped>
.modal-clip {
  background-color: rgba(#000000, 0.6);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
</style>

<template>
  <section>
    <transition
      @before-enter="fadeBeforeEnter"
      @enter="fadeEnter"
      @leave="fadeLeave"
    >
      <aside
        class="modal-clip swiper-no-swiping"
        v-if="
          $route.matched.length > 0 && $route.matched[0].name === 'ModalView'
        "
        @click.self="$router.go(-1)"
      />
    </transition>
    <slot></slot>
  </section>
</template>

<script>
import { fade } from "@/js/gsap-transitions-hooks";

export default {
  name: "ModalView",
  methods: {
    ...fade
  }
};
</script>
